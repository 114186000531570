import Vue from 'vue'
// axios
import axios from 'axios'
import router from '@/router'
import { initialAbility } from '@/libs/acl/config'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${process.env.VUE_APP_BACKEND_HOST}/api/`,
  timeout: 10000,
})

function logout() {
  // Remove userData from localStorage
  // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')

  // Remove userData from localStorage
  localStorage.removeItem('userData')

  // Reset ability
  this.$ability.update(initialAbility)

  this.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title: 'Nicht authorisiert!',
      icon: 'XIcon',
      variant: 'danger',
      text: 'Session abgelaufen, bitte erneut anmelden.',
    },
  })
  // Redirect to login page
  router.push({ name: 'auth-login' })
}

let lastLogoutTime = 0

function handleRequestError(error) {
  if (error.response && error.response.status === 401) {
    // Unauthorized error
    const now = Date.now()
    if (now - lastLogoutTime >= 10000) {
      lastLogoutTime = now
      logout.call(Vue.prototype)
    }
  }
  return Promise.reject(error)
}

// Add a request interceptor
axiosIns.interceptors.response.use(
  response => response,
  error => handleRequestError(error),
)

Vue.prototype.$http = axiosIns

export default axiosIns
