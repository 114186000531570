import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    organizationEvents: [
    ],
    suborganizationEvents: [
    ],
    clusterEvents: [
    ],
    localGroupEvents: [
    ],
    emmaEvents: [
    ],
  },
  getters: {
    getEmmaCalendarEventById: state => id => state.emmaEvents.filter(event => event.id === id),
  },
  mutations: {
    setEmmaEvents(state, events) {
      state.emmaEvents = []
      for (let i = 0; i < events.length; i += 1) {
        const event = events[i]
        event.calendar = 'emma'
        state.emmaEvents.push(event)
      }
    },
    addEmmaEvent(state, event) {
      state.emmaEvents.push(event)
    },
    async updateEmmaEvent(state, event) {
      const eventIndex = state.emmaEvents.findIndex(searchEvent => Number.parseInt(searchEvent.id, 10) === Number.parseInt(event.id, 10))
      state.emmaEvents[eventIndex] = event
    },
    removeEmmaEvent(state, event) {
      const eventIndex = state.emmaEvents.findIndex(searchEvent => Number.parseInt(searchEvent.id, 10) === Number.parseInt(event.id, 10))
      state.emmaEvents.splice(eventIndex, 1)
    },
  },
  actions: {
    async getEmmaEvents(state, serial) {
      const getEmmaEventsResponse = await axios.get(`/emmas/${serial}/appointments`)
      this.commit('calendar/setEmmaEvents', getEmmaEventsResponse.data)
    },
    async addEmmaEvent(state, event) {
      const backendEvent = {}

      backendEvent.title = event.title
      backendEvent.start = event.start
      backendEvent.end = event.end
      backendEvent.allday = event.allday
      backendEvent.audio = (event.audio) ? event.audio : ''

      const createdEmmaEventResponse = await axios.post(`/emmas/${event.scopeId}/appointments`, backendEvent)

      if (createdEmmaEventResponse.status === 200) {
        // eslint-disable-next-line no-param-reassign
        event.id = createdEmmaEventResponse.data.id
        this.commit('calendar/addEmmaEvent', event)
      } else {
        throw new Error('Could not create appointment on Emma', createdEmmaEventResponse)
      }
    },
    async removeEmmaEvent(state, event) {
      const deletedEmmaEventResponse = await axios.delete(`/emmas/${event.scopeId}/appointments/${event.id}`)
      if (deletedEmmaEventResponse.status === 200) {
        this.commit('calendar/removeEmmaEvent', event)
      } else {
        throw new Error('Could not delete appointment on Emma', deletedEmmaEventResponse)
      }
    },
    async updateEmmaEvent(state, event) {
      const backendEvent = {}
      backendEvent.id = event.id
      backendEvent.title = event.title
      backendEvent.start = Date.parse(event.start)
      backendEvent.end = Date.parse(event.end)
      backendEvent.allday = event.allday
      backendEvent.audio = (event.audio) ? event.audio : null

      const updatedEmmaEventResponse = await axios.put(`/emmas/${event.scopeId}/appointments/${event.id}`, backendEvent)
      if (updatedEmmaEventResponse.status === 200) {
        this.commit('calendar/updateEmmaEvent', event)
      } else {
        throw new Error('Could not update appointment on Emma', updatedEmmaEventResponse)
      }
    },
  },
}
