import axios from '@axios'

function makeid(length) {
  let result = ''
  const characters = '0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random()
      * charactersLength))
  }
  return `f-${result}`
}

export default {
  namespaced: true,
  state: {
    secondsBeforeOffline: 30,
    emmas: []
  },
  getters: {
    getEmmasByOrganizationId: state => id => state.emmas.filter(emma => emma.organizationId === id),
    getEmmasBySuborganizationId: state => id => state.emmas.filter(emma => emma.suborganizationId === id),
    getEmmasByClusterId: state => id => state.emmas.filter(emma => emma.clusterId === id),
    getEmmasByLocalGroupId: state => id => state.emmas.filter(emma => emma.localGroupId === id),
    getEmmaBySerial: state => serial => state.emmas.find(emma => emma.serial === serial)
  },
  mutations: {
    setEmmas(state, emmas) {
      state.emmas = emmas
    },
    addEmma(state, emma) {
      state.emmas.push(emma)
    },
    updateEmma(state, payload) {
      const emmaIndex = state.emmas.findIndex(emma => emma.serial === payload.emma.serial)
      state.emmas[emmaIndex].organizationId = payload.organizationData.selectedOrganization
      state.emmas[emmaIndex].suborganizationId = payload.organizationData.selectedSuborganization
      state.emmas[emmaIndex].clusterId = payload.organizationData.selectedCluster
      state.emmas[emmaIndex].localGroupId = payload.organizationData.selectedLocalGroup
    },
    // TODO: Remove when no more fake emmas exist
    triggerRandomHeartbeats(state) {
      for (let i = 0; i < state.emmas.length; i += 1) {
        if (state.emmas[i].fake) {
          const random = Math.random()
          if (random > 0.75) {
            state.emmas[i].timestamp = Date.now()
          }
        }
      }
    }
  },
  actions: {
    async addOrganizationEmmas(context, organizationId) {
      const emmas = await axios.get(`/organizations/${organizationId}/emmas`)
      for (let i = 0; i < emmas.data.length; i += 1) {
        const emma = {
          serial: emmas.data[i].serial,
          organizationId: emmas.data[i].organizationID,
          suborganizationId: emmas.data[i].suborganizationID,
          clusterId: emmas.data[i].clusterID,
          localGroupId: emmas.data[i].localGroupID,
          deepSleep: emmas.data[i].deepSleep,
          timestamp: undefined,
          createdAt: Date.now(),
          updatedAt: Date.now(),
          fake: false
        }
        context.commit('addEmma', emma)
      }
    },
    // async addFraunhoferEmma(context) {
    //   let hannah = {
    //     serial: 'hannah',
    //     organizationId: 3,
    //     suborganizationId: undefined,
    //     clusterId: undefined,
    //     localGroupId: undefined,
    //     timestamp: undefined,
    //     createdAt: Date.now(),
    //     updatedAt: Date.now(),
    //     fake: false
    //   }
    //   try {
    //     const hannahResponse = await axios.get('/emmas/hannah')
    //     if (hannahResponse.data !== '') {
    //       hannah = hannahResponse.data
    //       hannah.organizationId = 3
    //       hannah.fake = false
    //       hannah.timestamp = hannahResponse.data.lastHeartbeat
    //     }
    //   } catch (e) {
    //     console.log('Couldnt get real hannah, spoofing')
    //   }
    //   context.commit('addEmma', hannah)
    // },
    createFakeEmmas({ state, rootState, rootGetters }, amount) {
      for (let i = 0; i < amount; i += 1) {
        const randomOrg = rootState.organizations.organizations[Math.floor(Math.random() * rootState.organizations.organizations.length)]
        const randomSuborganizations = rootGetters['suborganizations/getSuborganizationByOrganizationId'](parseInt(randomOrg.id, 10))
        let randomSuborganization = randomSuborganizations[Math.floor(Math.random() * randomSuborganizations.length)]
        if (randomSuborganization === undefined) {
          randomSuborganization = { id: undefined }
        }

        let randomCluster
        if (randomSuborganization.id !== undefined) {
          const randomClusters = rootGetters['clusters/getClusterBySuborganizationId'](parseInt(randomSuborganization.id, 10))
          randomCluster = randomClusters[Math.floor(Math.random() * randomClusters.length)]
          while (randomCluster.id === 2) {
            randomCluster = randomClusters[Math.floor(Math.random() * randomClusters.length)]
          }
          if (!randomCluster) {
            randomCluster = { id: undefined }
          }
        } else {
          randomCluster = { id: undefined }
        }

        let randomLocalGroup
        if (randomCluster.id !== undefined) {
          const randomLocalGroups = rootGetters['localGroups/getLocalGroupsByClusterId'](parseInt(randomCluster.id, 10))
          randomLocalGroup = randomLocalGroups[Math.floor(Math.random() * randomLocalGroups.length)]
          if (randomLocalGroup === undefined) {
            randomLocalGroup = { id: undefined }
          }
        } else {
          randomLocalGroup = { id: undefined }
        }

        const interfaces = ['eth0', 'ppp0', 'wlan0']
        const randomIndex = Math.floor(Math.random() * interfaces.length)

        const emma = {
          serial: makeid(8),
          organizationId: randomOrg.id,
          suborganizationId: randomSuborganization.id,
          clusterId: randomCluster.id,
          localGroupId: randomLocalGroup.id,
          activeNetworkInterface: interfaces[randomIndex],
          timestamp: Date.now(),
          fake: true
        }

        state.emmas.push(emma)
      }
    },
    async updateEmma(context, payload) {
      const updateEmmaPayload = {
        organizationID: payload.organizationData.selectedOrganization,
        suborganizationID: payload.organizationData.selectedSuborganization,
        clusterID: payload.organizationData.selectedCluster,
        localGroupID: payload.organizationData.selectedLocalGroup
      }
      const updateEmmaResponse = await axios.put(`/emmas/${payload.emma.serial}/config/organizationData`, { organizationData: updateEmmaPayload })
      if (updateEmmaResponse.status === 200) {
        context.commit('updateEmma', payload)
      }
    },
    async updateEmmaHeartbeats(context) {
      const tempArray = JSON.parse(JSON.stringify(context.state.emmas))
      let heartbeatArray = []
      for (let i = 0; i < context.rootState.organizations.organizations.length; i += 1) {
        const emmas = await axios.get(`/organizations/${context.rootState.organizations.organizations[i].id}/emmas`)
        heartbeatArray = heartbeatArray.concat(emmas.data)
      }

      for (let i = 0; i < tempArray.length; i += 1) {
        if (!tempArray[i].fake) {
          for (let j = 0; j < heartbeatArray.length; j += 1) {
            // TODO: make work!
            if (tempArray.filter(emma => (emma.serial === heartbeatArray[j].serial)).length === 0) {
              heartbeatArray[j].fake = false
              this.commit('emmas/addEmma', heartbeatArray[j])
            }
            if (tempArray[i].serial === heartbeatArray[j].serial) {
              tempArray[i].timestamp = heartbeatArray[j].lastHeartbeat
              tempArray[i].deepSleep = heartbeatArray[j].deepSleep
              tempArray[i].activeNetworkInterface = heartbeatArray[j].activeNetworkInterface
              tempArray[i].availableNetworks = heartbeatArray[j].availableNetworks
              try {
                const ratingObject = await axios.get(`/statistics/${tempArray[i].serial}`)
                // eslint-disable-next-line no-underscore-dangle
                tempArray[i].rating = ratingObject.data.payload._value
                tempArray[i].sensorFault = ratingObject.data.payload.sensorFault
              } catch (e) {
                tempArray[i].rating = 0
                tempArray[i].sensorFault = undefined
              }
            }
          }
        }
      }
      this.commit('emmas/setEmmas', tempArray)
    },
    // eslint-disable-next-line no-unused-vars
    async simulateWakeword({ state, dispatch, commit }, payload) {
      const result = await axios.post(`/emmas/${payload.serial}/audio/simulate_wakeword`, {})

      if (result.status === 200) {
        return true
      }
      throw new Error(`Error simulating wakeword ${result.statusText}`)
    },
    // eslint-disable-next-line no-unused-vars
    async simulateVoiceInput({ state, dispatch, commit }, payload) {
      if (!payload) {
        throw new Error('Invalid arguments: missing payload in simulateVoiceInput')
      } else if (!payload.serial) {
        throw new Error('Invalid arguments: missing property serial in payload of simulateVoiceInput')
      } else if (!payload.text) {
        throw new Error('Invalid arguments: missing property text in payload of simulateVoiceInput')
      }

      const result = await axios.post(`/emmas/${payload.serial}/audio/fake_asr`, payload)

      if (result.status === 200) {
        return true
      }
      throw new Error(`Error simulating voice ${result.statusText}`)
    },
    // eslint-disable-next-line no-unused-vars
    async setMicrophoneStatus({ state, dispatch, commit }, payload) {
      if (!payload) {
        throw new Error('Invalid arguments: missing payload in simulateVoiceInput')
      } else if (!payload.serial) {
        throw new Error('Invalid arguments: missing property serial in payload of simulateVoiceInput')
      } else if (!Object.hasOwn(payload, 'state')) {
        throw new Error('Invalid arguments: missing property state in payload of simulateVoiceInput')
      }

      const result = await axios.post(`/emmas/${payload.serial}/audio/toggle_microphone`, payload)

      if (result.status === 200) {
        return true
      }
      throw new Error(`Error simulating voice ${result.statusText}`)
    },
    async saveWifiToEmma({ state, dispatch, commit }, payload) {
      console.log(payload.wifi)
      const updatedEmmaResult = await axios.post(`/emmas/${payload.serial}/config/wifi`, payload.wifi)
      if (updatedEmmaResult.status === 200) {
        return
      }
      throw new Error(`Error updating Wifi ${updatedEmmaResult.statusText}`)
    },
    async deleteWifiOffEmma({ state, dispatch, commit }, payload) {
      console.log(payload.wifi)
      const updatedEmmaResult = await axios.delete(`/emmas/${payload.serial}/config/wifi?ssid=${payload.wifi.ssid}`)
      if (updatedEmmaResult.status === 200) {
        return
      }
      throw new Error(`Error updating Wifi ${updatedEmmaResult.statusText}`)
    }
  }
}
