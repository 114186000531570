import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reminders: {
      drinking: {
        times: {
          morning: false,
          noon: false,
          evening: false,
        },
        days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
        timeDefinitions: {
          morning: '09:00:00',
          noon: '12:00:00',
          evening: '17:00:00',
        },
      },
      medication: {
        times: {
          morning: false,
          noon: false,
          evening: false,
        },
        days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
        timeDefinitions: {
          morning: '09:00:00',
          noon: '12:00:00',
          evening: '17:00:00',
        },
      },
    },
  },
  getters: {
  },
  mutations: {
    setDrinkingReminderTimes(state, payload) {
      state.reminders.drinking.times = payload
    },
    setDrinkingReminderTimeDefinitions(state, payload) {
      state.reminders.drinking.timeDefinitions = payload
    },
    setMedicationReminderTimes(state, payload) {
      state.reminders.medication.times = payload
    },
    setMedicationReminderTimeDefinitions(state, payload) {
      state.reminders.medication.timeDefinitions = payload
    },
  },
  actions: {
    async saveDrinkReminders({ state }, serial) {
      await axios.post(`/emmas/${serial}/reminders/drink_reminder`, state.reminders.drinking)
    },
    async saveMedReminders({ state }, serial) {
      await axios.post(`/emmas/${serial}/reminders/medi_reminder`, state.reminders.medication)
    },
  },
}
