import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/dashboard/organization',
    },
    {
      path: '/dashboard/organization',
      name: 'organizationDashboard',
      component: () => import('@/views/dashboards/organization/OrganizationDashboard.vue'),
      meta: {
        pageTitle: 'Organisation Dashboard',
        resource: 'organizationDashboard',
        breadcrumb: [
          {
            text: 'Organisation Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard/suborganization',
      name: 'suborganizationDashboard',
      component: () => import('@/views/dashboards/suborganization/SuborganizationDashboard.vue'),
      meta: {
        pageTitle: 'Land Dashboard',
        resource: 'suborganizationDashboard',
        breadcrumb: [
          {
            text: 'Land Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard/cluster',
      name: 'clusterDashboard',
      component: () => import('@/views/dashboards/cluster/ClusterDashboard.vue'),
      meta: {
        pageTitle: 'Haus Dashboard',
        resource: 'clusterDashboard',
        breadcrumb: [
          {
            text: 'Haus Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard/localGroup',
      name: 'localGroupDashboard',
      component: () => import('@/views/dashboards/local-group/LocalGroupDashboard.vue'),
      meta: {
        pageTitle: 'Wohnung Dashboard',
        resource: 'localGroupDashboard',
        breadcrumb: [
          {
            text: 'Wohnung Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard/localGroup/detail',
      name: 'localGroupDetailDashboard',
      component: () => import('@/views/dashboards/local-group/LocalGroupDetailDashboard.vue'),
      meta: {
        pageTitle: 'Wohnung Dashboard Detail',
        resource: 'localGroupDetailDashboard',
        breadcrumb: [
          {
            text: 'Wohnung Dashboard Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard/localGroup/adl',
      name: 'ADLDashboard',
      component: () => import('@/views/dashboards/local-group/ADLDashboard.vue'),
      meta: {
        pageTitle: 'ADL Dashboard',
        resource: 'localGroupDetailDashboard',
        breadcrumb: [
          {
            text: 'ADL Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard/emma',
      name: 'emmaDashboard',
      component: () => import('@/views/dashboards/emma/EmmaDashboard.vue'),
      meta: {
        pageTitle: 'Emma Dashboard',
        resource: 'emmaDashboard',
        breadcrumb: [
          {
            text: 'Emma Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/api',
      name: 'api',
      component: () => import('@/views/dashboards/testing/ApiTesting.vue'),
      meta: {
        pageTitle: 'API Testing',
        resource: 'API Testing',
        breadcrumb: [
          {
            text: 'API Testing',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin/users',
      name: 'userAdmin',
      component: () => import('@/views/dashboards/admin/UserManagement.vue'),
      meta: {
        pageTitle: 'User Management',
        resource: 'User Management',
        breadcrumb: [
          {
            text: 'User Management',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin/organizations',
      name: 'organizationAdmin',
      component: () => import('@/views/dashboards/admin/OrganizationManagement.vue'),
      meta: {
        pageTitle: 'Organizations Management',
        resource: 'Organizations Management',
        breadcrumb: [
          {
            text: 'Organizations Management',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      component: () => import('@/views/authentication/Login-v2.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/authentication/Login-v2.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  store.commit('app/CLEAR_PAGE_TITLE')
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
