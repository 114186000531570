import axios from '@/libs/axios'

function spoofOfflineHistory(emmaAmount) {
  const history = []
  for (let i = 0; i < 14; i += 1) {
    const date = new Date().setDate(new Date().getDate() - i)
    let random = Math.floor(Math.random() * (emmaAmount + 1))
    random = Math.floor(Math.floor(random * Math.random()) * Math.random())
    history.push([date, random])
  }
  return history
}

export default {
  namespaced: true,
  state: {
    localGroups: [],
  },
  getters: {
    getLocalGroupsByClusterId: state => id => state.localGroups.filter(localGroup => localGroup.Cluster.id === id),
    getLocalGroupById: state => id => state.localGroups.find(localGroup => localGroup.id === id),
    getLocalGroupByName: state => name => state.localGroups.find(localGroup => localGroup.name === name),

  },
  mutations: {
    setLocalGroups(state, localGroups) {
      state.localGroups = localGroups
    },
    addLocalGroup(state, localGroup) {
      const completeLocalGroup = localGroup
      completeLocalGroup.offlineEmmaHistory = spoofOfflineHistory(1).reverse()
      completeLocalGroup.alarmHistory = Math.floor(Math.random() * 100)
      state.localGroups.push(completeLocalGroup)
    },
    editLocalGroup(state, localGroup) {
      const editLocalGroup = state.localGroups.find(searchLocalGroup => searchLocalGroup.id === localGroup.id)

      editLocalGroup.name = localGroup.name
      editLocalGroup.address = localGroup.address
      editLocalGroup.Cluster = localGroup.Cluster
      editLocalGroup.updatedAt = localGroup.updatedAt
      editLocalGroup.offlineEmmaHistory = spoofOfflineHistory().reverse()
      editLocalGroup.alarmHistory = Math.floor(Math.random() * 100)
    },
    removeLocalGroup(state, localGroup) {
      const localGroupIndex = state.localGroups.findIndex((searchLocalGroup => searchLocalGroup.id === localGroup.id))
      state.localGroups.splice(localGroupIndex, 1)
    },
  },
  actions: {
    async addLocalGroup(state, localGroup) {
      const finalLocalGroup = {}
      finalLocalGroup.name = localGroup.name
      finalLocalGroup.address = localGroup.address
      finalLocalGroup.clusterID = localGroup.Cluster.id

      const createdLocalGroupResult = await axios.post('/local-groups', finalLocalGroup)

      if (createdLocalGroupResult.status === 200) {
        const getCreatedLocalGroupResult = await axios.get(`/local-groups/${createdLocalGroupResult.data.id}`)
        if (getCreatedLocalGroupResult.status === 200) {
          this.commit('localGroups/addLocalGroup', getCreatedLocalGroupResult.data)
        } else {
          throw new Error(`Error getting localGroup after creation ${getCreatedLocalGroupResult.statusText} `)
        }
      } else {
        throw new Error(`Error creating localGroup ${createdLocalGroupResult.statusText}`)
      }
    },
    async editLocalGroup(state, localGroup) {
      const finalLocalGroup = {}
      finalLocalGroup.id = localGroup.id
      finalLocalGroup.name = localGroup.name
      finalLocalGroup.address = localGroup.address
      finalLocalGroup.clusterID = localGroup.Cluster.id

      const updatedLocalGroupResult = await axios.patch(`/local-groups/${localGroup.id}`, finalLocalGroup)

      if (updatedLocalGroupResult.status === 200) {
        const getUpdatedLocalGroupResult = await axios.get(`/local-groups/${updatedLocalGroupResult.data.id}`)
        if (getUpdatedLocalGroupResult.status === 200) {
          this.commit('localGroups/editLocalGroup', updatedLocalGroupResult.data)
        } else {
          throw new Error(`Error getting localGroup after creation ${getUpdatedLocalGroupResult.statusText} `)
        }
      } else {
        throw new Error(`Error creating localGroup ${updatedLocalGroupResult.statusText}`)
      }
    },
    async getAllLocalGroups() {
      const getLocalGroupsResonse = await axios.get('/local-groups')

      for (let i = 0; i < getLocalGroupsResonse.data.length; i += 1) {
        getLocalGroupsResonse.data[i].offlineEmmaHistory = spoofOfflineHistory(1).reverse()
        getLocalGroupsResonse.data[i].alarmHistory = Math.floor(Math.random() * 100)
      }

      this.commit('localGroups/setLocalGroups', getLocalGroupsResonse.data)
    },
    // eslint-disable-next-line no-empty-pattern
    async pullLocalGroupsByOrgId({}, id) {
      const getLocalGroupsResonse = await axios.get(`/organizations/${id}/local-groups`)
      for (let i = 0; i < getLocalGroupsResonse.data.length; i += 1) {
        this.commit('localGroups/addLocalGroup', getLocalGroupsResonse.data[i])
      }
      return getLocalGroupsResonse.status
    },
    async removeLocalGroup(state, localGroup) {
      const removedLocalGroupResult = await axios.delete(`/local-groups/${localGroup.id}`)
      if (removedLocalGroupResult.status === 200) {
        this.commit('localGroups/removeLocalGroup', localGroup)
      } else {
        throw new Error(`Error removing localGroup ${removedLocalGroupResult.statusText}`)
      }
    },
    async spoofHistory({ rootGetters, state }) {
      for (let i = 0; i < state.localGroups.length; i += 1) {
        const emmaAmount = rootGetters['emmas/getEmmasByLocalGroupId'](parseInt(state.localGroups[i].id, 10)).length
        state.localGroups[i].offlineEmmaHistory = spoofOfflineHistory(emmaAmount).reverse()
        state.localGroups[i].alarmHistory = Math.floor(Math.random() * emmaAmount)
      }
    },
  },
}
