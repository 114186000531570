import axios from '@/libs/axios'
import md5 from 'md5'

export default {
  namespaced: true,
  state: {
    currentUser: undefined,
  },
  getters: {
  },
  mutations: {
    setCurrentUser(state, payload) {
      state.currentUser = payload
    },
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async updateUser({}, payload) {
      const { name } = payload
      const { email } = payload
      const { access } = payload
      const { id } = payload
      const { organizationIDs } = payload
      await axios.put(`/users/${id}`, {
        organizationIDs, name, email, access,
      })
    },
    // eslint-disable-next-line no-empty-pattern
    async addUser({}, payload) {
      const { name } = payload
      const { email } = payload
      const { access } = payload
      const password = md5(payload.password)
      const { organizationIDs } = payload
      await axios.post('/users', {
        organizationIDs, name, email, access, password,
      })
    },
    // eslint-disable-next-line no-empty-pattern
    async deleteUser({}, payload) {
      const { id } = payload
      await axios.delete(`/users/${id}`)
    },
  },
}
