import axios from '@/libs/axios'

function spoofOfflineHistory(emmaAmount) {
  const history = []
  for (let i = 0; i < 14; i += 1) {
    const date = new Date().setDate(new Date().getDate() - i)
    let random = Math.floor(Math.random() * (emmaAmount + 1))
    random = Math.floor(Math.floor(random * Math.random()) * Math.random())
    history.push([date, random])
  }
  return history
}

export default {
  namespaced: true,
  state: {
    suborganizations: [],
  },
  getters: {
    getSuborganizationById: state => id => state.suborganizations.find(suborganization => suborganization.id === id),
    getSuborganizationByName: state => name => state.suborganizations.find(suborganization => suborganization.name === name),
    getSuborganizationByOrganizationId: state => id => state.suborganizations.filter(suborganization => suborganization.organizationID === id),
  },
  mutations: {
    setSuborganizations(state, payload) {
      state.suborganizations = payload
    },
    addSuborganizations(state, payload) {
      state.suborganizations.push(payload)
    },
    addSuborganization(state, suborganization) {
      const completeSuborganization = suborganization
      completeSuborganization.offlineEmmaHistory = spoofOfflineHistory().reverse()
      completeSuborganization.alarmHistory = Math.floor(Math.random() * 100)
      state.suborganizations.push(completeSuborganization)
    },
    editSuborganization(state, suborganization) {
      const editSuborganization = state.suborganizations.find(searchSuborganization => searchSuborganization.id === suborganization.id)
      editSuborganization.name = suborganization.name
      editSuborganization.organizationID = suborganization.organizationID
      editSuborganization.country = suborganization.country
      editSuborganization.updatedAt = suborganization.updatedAt
      editSuborganization.createdAt = suborganization.createdAt
      editSuborganization.offlineEmmaHistory = spoofOfflineHistory().reverse()
      editSuborganization.alarmHistory = Math.floor(Math.random() * 100)
    },
    removeSuborganization(state, suborganization) {
      const suborganizationIndex = state.suborganizations.findIndex((searchSuborganization => searchSuborganization.id === suborganization.id))
      state.suborganizations.splice(suborganizationIndex, 1)
    },
  },
  actions: {
    async getAllSuborganizations() {
      const getSuborganizationsResponse = await axios.get('/suborganizations')

      for (let i = 0; i < getSuborganizationsResponse.data.length; i += 1) {
        getSuborganizationsResponse.data[i].offlineEmmaHistory = spoofOfflineHistory(1).reverse()
        getSuborganizationsResponse.data[i].alarmHistory = Math.floor(Math.random() * 100)
      }
      this.commit('suborganizations/setSuborganizations', getSuborganizationsResponse.data)
    },
    // eslint-disable-next-line no-empty-pattern
    async pullSuborganizationsByOrgId({}, id) {
      const getSuborganizationsResponse = await axios.get(`/organizations/${id}/suborganizations`)
      for (let i = 0; i < getSuborganizationsResponse.data.length; i += 1) {
        this.commit('suborganizations/addSuborganization', getSuborganizationsResponse.data[i])
      }
      return getSuborganizationsResponse.status
    },
    async addSuborganization(state, suborganization) {
      const createdSuborganizationResult = await axios.post('/suborganizations', suborganization)

      if (createdSuborganizationResult.status === 200) {
        const getCreatedSuborganizationResult = await axios.get(`/suborganizations/${createdSuborganizationResult.data.id}`)
        if (getCreatedSuborganizationResult.status === 200) {
          this.commit('suborganizations/addSuborganization', getCreatedSuborganizationResult.data)
        } else {
          throw new Error(`Error getting suborganization after creation ${getCreatedSuborganizationResult.statusText} `)
        }
      } else {
        throw new Error(`Error creating suborganization ${createdSuborganizationResult.statusText}`)
      }
    },
    async editSuborganization(state, suborganization) {
      const editSuborganizationResult = await axios.patch(`/suborganizations/${suborganization.id}`, suborganization)
      if (editSuborganizationResult.status === 200) {
        const getEditSuborganizationResult = await axios.get(`/suborganizations/${editSuborganizationResult.data.id}`)
        if (getEditSuborganizationResult.status === 200) {
          this.commit('suborganizations/editSuborganization', getEditSuborganizationResult.data)
        } else {
          throw new Error(`Error getting suborganization after creation ${getEditSuborganizationResult.statusText} `)
        }
      } else {
        throw new Error(`Error editing suborganization ${editSuborganizationResult.statusText}`)
      }
      // this.commit('suborganizations/editSuborganization', suborganization)
    },
    async removeSuborganization(state, suborganization) {
      const removedSuborganizationResult = await axios.delete(`/suborganizations/${suborganization.id}`)
      if (removedSuborganizationResult.status === 200) {
        this.commit('suborganizations/removeSuborganization', suborganization)
      } else {
        throw new Error(`Error creating cluster ${removedSuborganizationResult.statusText}`)
      }
    },
    async spoofHistory({ rootGetters, state }) {
      for (let i = 0; i < state.suborganizations.length; i += 1) {
        const emmaAmount = rootGetters['emmas/getEmmasBySuborganizationId'](parseInt(state.suborganizations[i].id, 10)).length
        state.suborganizations[i].offlineEmmaHistory = spoofOfflineHistory(emmaAmount).reverse()
        state.suborganizations[i].alarmHistory = Math.floor(Math.random() * emmaAmount)
      }
    },
  },
}
