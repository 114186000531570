import axios from '@axios'

export default {
  namespaced: true,
  state: {
    contacts: [],
    settings: {},
    selectedEmma: {},
  },
  getters: {
  },
  mutations: {
    setContacts(state, contacts) {
      state.contacts = contacts
    },
    setSettings(state, settings) {
      state.settings = settings
    },
    setDrinkReminder(state, drinkReminder) {
      state.settings.reminder.drinking = drinkReminder
    },
    setMedicationReminder(state, medicationReminder) {
      state.settings.reminder.medication = medicationReminder
    },
    setSelectedEmma(state, selectedEmma) {
      state.selectedEmma = selectedEmma
    },
    setCallName(state, callName) {
      state.settings.identity.callName = callName
    },
  },
  actions: {
    async getContactList({ state, commit }) {
      const contactList = await axios.get(`/emmas/${state.selectedEmma.serial}/contacts`)
      commit('setContacts', contactList.data)
    },
    async saveDrinkReminders({ state }, payload) {
      await axios.post(`/emmas/${state.selectedEmma.serial}/reminders/drink_reminder`, payload)
    },
    async saveMedicationReminders({ state }, payload) {
      await axios.post(`/emmas/${state.selectedEmma.serial}/reminders/medi_reminder`, payload)
    },
    async saveCallName({ state, dispatch, commit }, payload) {
      const result = await axios.post(`/emmas/${state.selectedEmma.serial}/identity/callname`, payload)
      if (result.status === 200) {
        commit('setCallName', payload.callName)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async simulateWakeword({ state, dispatch, commit }, payload) {
      const result = await axios.post(`/emmas/${payload.serial}/audio/simulate_wakeword`, {})

      if (result.status === 200) {
        return true
      }
      throw new Error(`Error simulating wakeword ${result.statusText}`)
    },
    // eslint-disable-next-line no-unused-vars
    async simulateVoiceInput({ state, dispatch, commit }, payload) {
      if (!payload) {
        throw new Error('Invalid arguments: missing payload in simulateVoiceInput')
      } else if (!payload.serial) {
        throw new Error('Invalid arguments: missing property serial in payload of simulateVoiceInput')
      } else if (!payload.text) {
        throw new Error('Invalid arguments: missing property text in payload of simulateVoiceInput')
      }

      const result = await axios.post(`/emmas/${payload.serial}/audio/fake_asr`, payload)

      if (result.status === 200) {
        return true
      }
      throw new Error(`Error simulating voice ${result.statusText}`)
    },
    // eslint-disable-next-line no-unused-vars
    async setMicrophoneStatus({ state, dispatch, commit }, payload) {
      if (!payload) {
        throw new Error('Invalid arguments: missing payload in simulateVoiceInput')
      } else if (!payload.serial) {
        throw new Error('Invalid arguments: missing property serial in payload of simulateVoiceInput')
      } else if (!Object.hasOwn(payload, 'state')) {
        throw new Error('Invalid arguments: missing property state in payload of simulateVoiceInput')
      }

      const result = await axios.post(`/emmas/${payload.serial}/audio/toggle_microphone`, payload)

      if (result.status === 200) {
        return true
      }
      throw new Error(`Error simulating voice ${result.statusText}`)
    },
  },
}
