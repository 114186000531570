import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import emmas from '@/store/emmas'
import suborganizations from '@/store/suborganizations'
import clusters from '@/store/clusters'
import localGroups from '@/store/localGroups'
import reminders from '@/store/reminders'
import breadcrumbs from '@/store/breadcrumbs'
import organizations from '@/store/organizations'
import app from './app'
import calendar from './calendar'
import users from './users'
import smarthome from './smarthome'
import appConfig from './app-config'
import emmasettings from './emmasettings'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    breadcrumbs,
    verticalMenu,
    organizations,
    suborganizations,
    clusters,
    localGroups,
    emmas,
    reminders,
    calendar,
    smarthome,
    emmasettings,
    users,
  },
  strict: process.env.DEV,
})
