import axios from '@/libs/axios'

function spoofOfflineHistory(emmaAmount) {
  const history = []
  for (let i = 0; i < 14; i += 1) {
    const date = new Date().setDate(new Date().getDate() - i)
    let random = Math.floor(Math.random() * (emmaAmount + 1))
    random = Math.floor(Math.floor(random * Math.random()) * Math.random())
    history.push([date, random])
  }
  return history
}

export default {
  namespaced: true,
  state: {
    organizations: [],
  },
  getters: {
    getOrgById: state => id => state.organizations.find(organization => organization.id === id),
  },
  mutations: {
    setOrganizations(state, payload) {
      state.organizations = payload
    },
    addOrganization(state, payload) {
      const completeOrganization = payload
      completeOrganization.offlineEmmaHistory = spoofOfflineHistory().reverse()
      completeOrganization.alarmHistory = Math.floor(Math.random() * 100)
      state.organizations.push(completeOrganization)
    },
  },
  actions: {
    async addOrganization(state, organization) {
      await axios.post('/organizations', organization)
    },
    async updateOrganization(state, organization) {
      await axios.patch(`/organizations/${organization.id}`, organization)
    },
    // eslint-disable-next-line no-empty-pattern
    async pullOrganizationById({}, id) {
      const getOrganizationResponse = await axios.get(`/organizations/${id}`)
      this.commit('organizations/addOrganization', getOrganizationResponse.data)
      return getOrganizationResponse.status
    },
    async spoofHistory({ rootGetters, state }) {
      for (let i = 0; i < state.organizations.length; i += 1) {
        const emmaAmount = rootGetters['emmas/getEmmasByOrganizationId'](parseInt(state.organizations[i].id, 10)).length
        state.organizations[i].offlineEmmaHistory = spoofOfflineHistory(emmaAmount).reverse()
        state.organizations[i].alarmHistory = Math.floor(Math.random() * emmaAmount)
      }
    },
  },
}
