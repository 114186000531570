export default {
  namespaced: true,
  state: {
    breadcrumbs: [],
  },
  getters: {
  },
  mutations: {
    addBreadcrumb(state, val) {
      for (let i = 0; i < state.breadcrumbs.length; i += 1) {
        state.breadcrumbs[i].active = false
      }
      state.breadcrumbs.push(val)
    },
    setBreadcrumbs(state, val) {
      state.breadcrumbs = val
    },
    clearBreadcrumbs(state) {
      state.breadcrumbs = []
    },
    clearBreadcrumbsAfterCurrent(state, val) {
      const threshold = state.breadcrumbs.findIndex(breadcrumb => breadcrumb.text === val)
      state.breadcrumbs[threshold].active = true
      state.breadcrumbs.splice(threshold + 1, state.breadcrumbs.length)
    },
  },
  actions: {},
}
