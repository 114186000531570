import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    mapping: { devices: {}, groups: {} },
  },
  getters: {
  },
  mutations: {
    setMapping(state, mapping) {
      state.mapping = mapping
    },
    async addDevice(state, device) {
      state.mapping.devices[device.id] = {
        id: device.id, connection: device.connection, type: device.type, appliance: device.appliance,
      }
      if (device.friendlyName !== '') {
        state.mapping.devices[device.id].friendlyName = device.friendlyName
      }
      if (Object.prototype.hasOwnProperty.call(device, 'group')) {
        if (Object.prototype.hasOwnProperty.call(state.mapping.groups, device.group)) {
          if (!state.mapping.groups[device.group].includes(device.id)) {
            state.mapping.groups[device.group].push(device.id)
          }
        } else {
          state.mapping.groups[device.group] = [device.id]
        }
        state.mapping.devices[device.id].group = device.group
      }
      console.log(state.mapping.devices[device.id])
    },
    async removeDevice(state, device) {
      delete state.mapping.devices[device.id]
      if (Object.prototype.hasOwnProperty.call(device, 'group')) {
        state.mapping.groups[device.group] = state.mapping.groups[device.group].filter(item => item !== device.id)
        if (state.mapping.groups[device.group].length === 0) {
          delete state.mapping.groups[device.group]
        }
      }
    },
    async removeDeviceFromGroup(state, device) {
      if (Object.prototype.hasOwnProperty.call(device, 'group')) {
        state.mapping.groups[device.group] = state.mapping.groups[device.group].filter(item => item !== device.id)
        if (state.mapping.groups[device.group].length === 0) {
          delete state.mapping.groups[device.group]
        }
        delete state.mapping.devices[device.id].group
      }
    },
    resetMapping(state) {
      state.mapping = { devices: {}, groups: {} }
    },
  },
  actions: {
    async addDevice(context, device) {
      await context.commit('addDevice', device)
    },
    async removeDevice(context, device) {
      const deviceToRemove = device
      if (device.connection === 'hue') {
        deviceToRemove.id = device.uniqueid
      }
      await context.commit('removeDevice', deviceToRemove)
    },
    async removeDeviceFromGroup(context, device) {
      await context.commit('removeDeviceFromGroup', device)
    },
    async getSmarthomeMapping(context, payload) {
      if (!payload) {
        throw new Error('Invalid arguments: missing payload in getSmarthomeMapping')
      } else if (!payload.serial) {
        throw new Error('Invalid arguments: missing property serial in payload of getSmarthomeMapping')
      }
      const smarthomeMapping = await axios.get(`/emmas/${payload.serial}/smarthome/mapping`)
      context.commit('setMapping', smarthomeMapping.data)
    },
    async sendSmarthomeMapping(context, payload) {
      try {
        const smarthomeMapping = await axios.post(`/emmas/${payload.serial}/smarthome/mapping`, context.state.mapping)
        if (smarthomeMapping.status === 200) {
          context.commit('setMapping', smarthomeMapping.data)
        }
      } catch (e) {
        throw new Error(e)
      }
    },
  },
}
