import axios from '@/libs/axios'

function spoofOfflineHistory(emmaAmount) {
  const history = []
  for (let i = 0; i < 14; i += 1) {
    const date = new Date().setDate(new Date().getDate() - i)
    let random = Math.floor(Math.random() * (emmaAmount + 1))
    random = Math.floor(Math.floor(random * Math.random()) * Math.random())
    history.push([date, random])
  }
  return history
}

export default {
  namespaced: true,
  state: {
    clusters: [],
  },
  getters: {
    getClusterById: state => id => state.clusters.find(cluster => cluster.id === id),
    getClusterBySuborganizationId: state => id => state.clusters.filter(cluster => cluster.Suborganization.id === id),
    getClusterByName: state => name => state.clusters.find(cluster => cluster.name === name),

  },
  mutations: {
    setClusters(state, clusters) {
      state.clusters = clusters
    },
    addCluster(state, cluster) {
      const completeCluster = cluster
      completeCluster.offlineEmmaHistory = spoofOfflineHistory().reverse()
      completeCluster.alarmHistory = Math.floor(Math.random() * 100)
      state.clusters.push(completeCluster)
    },
    editCluster(state, cluster) {
      const editCluster = state.clusters.find(searchCluster => searchCluster.id === cluster.id)

      editCluster.name = cluster.name
      editCluster.address = cluster.address
      editCluster.city = cluster.city
      editCluster.postalcode = cluster.postalcode
      editCluster.country = cluster.country
      editCluster.Suborganization = cluster.Suborganization
      editCluster.updatedAt = cluster.updatedAt
      editCluster.offlineEmmaHistory = spoofOfflineHistory().reverse()
      editCluster.alarmHistory = Math.floor(Math.random() * 100)
    },
    removeCluster(state, cluster) {
      const clusterIndex = state.clusters.findIndex((searchCluster => searchCluster.id === cluster.id))
      state.clusters.splice(clusterIndex, 1)
    },
  },
  actions: {
    async addCluster(state, cluster) {
      const finalCluster = {}
      finalCluster.name = cluster.name
      finalCluster.address = cluster.address
      finalCluster.city = cluster.city
      finalCluster.postalcode = cluster.postalcode
      finalCluster.country = cluster.country
      finalCluster.suborganizationID = cluster.Suborganization.id

      const createdClusterResult = await axios.post('/clusters', finalCluster)

      if (createdClusterResult.status === 200) {
        const getCreatedClusterResult = await axios.get(`/clusters/${createdClusterResult.data.id}`)
        if (getCreatedClusterResult.status === 200) {
          this.commit('clusters/addCluster', getCreatedClusterResult.data)
        } else {
          throw new Error(`Error getting cluster after creation ${getCreatedClusterResult.statusText} `)
        }
      } else {
        throw new Error(`Error creating cluster ${createdClusterResult.statusText}`)
      }
    },
    async editCluster(state, cluster) {
      const finalCluster = {}
      finalCluster.id = cluster.id
      finalCluster.name = cluster.name
      finalCluster.address = cluster.address
      finalCluster.city = cluster.city
      finalCluster.postalcode = cluster.postalcode
      finalCluster.country = cluster.country
      finalCluster.suborganizationID = cluster.Suborganization.id

      const updatedClusterResult = await axios.patch(`/clusters/${cluster.id}`, finalCluster)

      if (updatedClusterResult.status === 200) {
        const getUpdatedClusterResult = await axios.get(`/clusters/${updatedClusterResult.data.id}`)
        if (getUpdatedClusterResult.status === 200) {
          this.commit('clusters/editCluster', updatedClusterResult.data)
        } else {
          throw new Error(`Error getting cluster after creation ${getUpdatedClusterResult.statusText} `)
        }
      } else {
        throw new Error(`Error creating cluster ${updatedClusterResult.statusText}`)
      }
    },
    async removeCluster(state, cluster) {
      const removedClusterResult = await axios.delete(`/clusters/${cluster.id}`)
      if (removedClusterResult.status === 200) {
        this.commit('clusters/removeCluster', cluster)
      } else {
        throw new Error(`Error creating cluster ${removedClusterResult.statusText}`)
      }
    },
    async getAllClusters() {
      const getClustersResponse = await axios.get('/clusters')

      for (let i = 0; i < getClustersResponse.data.length; i += 1) {
        getClustersResponse.data[i].offlineEmmaHistory = spoofOfflineHistory(1).reverse()
        getClustersResponse.data[i].alarmHistory = Math.floor(Math.random() * 100)
      }

      this.commit('clusters/setClusters', getClustersResponse.data)
    },
    // eslint-disable-next-line no-empty-pattern
    async pullClustersByOrgId({}, id) {
      const getClustersResponse = await axios.get(`/organizations/${id}/clusters`)
      for (let i = 0; i < getClustersResponse.data.length; i += 1) {
        this.commit('clusters/addCluster', getClustersResponse.data[i])
      }
      return getClustersResponse.status
    },
    async spoofHistory({ rootGetters, state }) {
      for (let i = 0; i < state.clusters.length; i += 1) {
        const emmaAmount = rootGetters['emmas/getEmmasByClusterId'](parseInt(state.clusters[i].id, 10)).length
        state.clusters[i].offlineEmmaHistory = spoofOfflineHistory(emmaAmount).reverse()
        state.clusters[i].alarmHistory = Math.floor(Math.random() * emmaAmount)
      }
    },
  },
}
